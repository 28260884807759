import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Projects from "../components/projects"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={"Welcome to Baran's corner of the web, here you'll find a blog."}>
      <SEO title={siteTitle} />
      <Bio />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug} style={{ marginBottom: rhythm(2.5) }}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                  color: `rgb(var(--textTitle))`,
                  fontFamily: "Lora, PT Serif, serif",
                }}
              >
                <Link
                  style={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `inherit`,
                  }}
                  to={node.fields.slug}
                >
                  {title}
                </Link>
              </h3>
              <div
                style={{
                  display: `flex`,
                  color: `inherit`,
                }}
              >
                Written around {node.frontmatter.date} — {node.fields.readingTime.text}
              </div>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
