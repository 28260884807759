import React from "react"

function projects() {
  return (
    <section>
      <article>
        <h2>E-commerce Website (Wingroad)</h2>
        <p>
          Completed as a University project, with improvements made after
          submission. Used TailwindCSS and Handlebars.js for frontend, Koa.js
          for the backend, and Ava for unit testing. Sellers create listings for
          their products; buyers view the product listings and can contact the
          seller. SQLite3 with users&apos; email and password for
          authentication, with care taken to prevent SQL injections.
        </p>
        <div className="project-links">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://wingroad.onrender.com"
            title="wingroad.onrender.com"
          >
            Live Site
          </a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://github.com/baranbbr/wingroad"
            title="github.com/baranbbr/wingroad"
          >
            GitHub repo
          </a>
        </div>
      </article>
      <article>
        <h2>Minecraft &amp; Discord Chatbot</h2>
        <p>
          Used Django in Python, Discord API and Minecraft Pi edition to create
          a chatbot that can recognise and respond to queries in the official
          Minecraft game as well as on Discord. The chatbot was hosted on a
          server which allowed it to be used on multiple clients simultaneously.
          By using Agile methodology the project was completed on time and up to
          requirements. I created the Minecraft API using Django and obtained
          data for the API using BeautifulSoup on Python.
        </p>
        <div className="project-links">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://youtu.be/YGjHxd8RXtc"
            title="youtu.be/YGjHxd8RXtc"
          >
            Demo Video
          </a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://github.com/baranbbr/minecraftchatbot"
            title="github.com/baranbbr/minecraftchatbot"
          >
            GitHub repo
          </a>
        </div>
      </article>
      <article>
        <h2>WordPress Content Viewer App</h2>
        <p>
          Completed in a group as a hackathon project. React Native was used to
          create the app which allows the content of WordPress websites to be
          viewable on mobile, allowing the customisation of the viewer to suit
          the user&apos;s needs in a UI and UX focused way. I implemented the UI
          with a focus on functionality and UX using React Native.
        </p>
        <div className="project-links">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://youtu.be/O9F__L5f77Q"
            title="youtu.be/O9F__L5f77Q"
          >
            Demo Video
          </a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://github.com/baranbbr/parakeet-wp"
            title="github.com/baranbbr/parakeet-wp"
          >
            GitHub repo
          </a>
        </div>
      </article>
      <article>
        <h2>To-do List</h2>
        <p>
          Created using PHP. Uses a MySQL database for storing todos. REST API
          created using PHP, with CRUD operations. Website styled using
          TailwindCSS. Purpose of this project was to familiarise myself with
          PHP and understand how to create a REST API in PHP.
        </p>
        <div className="project-links">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://assignment-tracker-php.herokuapp.com"
            title="assignment-tracker-php.herokuapp.com"
          >
            Live Site
          </a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://github.com/baranbbr/assignment_tracker_php"
            title="github.com/baranbbr/assignment_tracker_php"
          >
            GitHub repo
          </a>
        </div>
      </article>
    </section>
  )
}

export default projects
